import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <div>
      <Link to={"/"}>回到首页</Link>
    </div>
  )
}
